<template>
  <div class="container-fluid p-tb-2">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-xs-12">
          <h1>Order under my brand</h1>
        </div>
      </div>
      <div v-if="!loading && item" class="row p-tb-1">
        <div v-if="placed" class="col-lg-12 col-xs-12 text-center">
          <div class="p-b-1">Thank you for your order, our manager will contact you soon</div>
          <router-link to="/orders" class="underlined text-color-purple">Go to the orders history</router-link>
        </div>
        <div v-else class="col-lg-12 col-xs-12">
          <form class="row" @submit.prevent="submit">
            <div class="col-lg-12 col-xs-12 mb-2" v-if="errorMessage">
              <div class="error-msg">{{ errorMessage }}</div>
            </div>
            <div class="col-lg-12 col-xs-12">
              <div class="order-item cart-item tile">
                <div class="item-info">
                  <div class="tile-image mr-2 text-center">
                    <img :src="productImage" :alt="item.title" height="70" />
                  </div>
                  <div class="item-title" v-if="item">
                    <router-link :to="`/product/${item.slug}`" class="text-color-purple underlined">
                      {{ item.title }}
                    </router-link>
                    <span class="ml-2 medium-font">from ${{ item.price }} per item</span>
                  </div>
                </div>
                <span v-if="loading">Loading...</span>
                <div class="actions">
                  <span class="mr-2 medium-font">Quantity: </span>
                  <input
                    type="number"
                    :min="item.minimalQty"
                    style="width: 100px"
                    v-model="quantity"
                    placeholder="Quantity"
                    required
                  />
                </div>
              </div>
            </div>
            <FormField class="col-lg-8 col-xs-12">
              <template>
                <h3>Comment</h3>
                <textarea v-model="comment" rows="4" />
              </template>
            </FormField>
            <div class="col-lg-4 col-xs-12">
              <p class="note" style="margin-top: 25px; color: #999">
                *The cost can differ significantly f rom the initial one and depends on the details of your order. You
                will find out the final cost after processing the order.
              </p>
              <div>
                <button
                  v-if="!placing"
                  class="rounded-5 text-medium medium-font button text-color-white bg-light-green"
                  type="submit"
                >
                  Place order
                </button>
                <div v-else class="rounded-5 text-medium medium-font button text-color-white bg-light-green loading-bg">
                  Please wait
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */

import FormField from 'Components/FormField';
import ItemTile from 'Components/ItemTile';

export default {
  name: 'ProductOrder',
  components: { ItemTile, FormField },
  data() {
    return {
      quantity: 100,
      comment: '',
      loading: false,
      placing: false,
      placed: false,
      item: null,
      errorMessage: '',
    };
  },
  watch: {
    slug() {
      this.loadItem(this.slug);
    },
  },
  computed: {
    slug() {
      return this.$attrs.slug;
    },
    productImage() {
      return this.item?.image || '/img/logo-small.svg';
    },
  },
  methods: {
    setLoader(state, prop = 'loading') {
      if (prop in this.$data) {
        this[prop] = state;
      }
      this.$store.dispatch('loader', state);
    },
    loadItem(slug) {
      this.setLoader(true);
      this.$api
        .getProductBySlug(slug)
        .then(model => {
          this.$set(this.$data, 'item', model);
          this.quantity = model.minimalQty;
          this.setLoader(false);
        })
        .catch(err => {
          console.log(err);
          this.setLoader(false);
        });
    },
    submit() {
      const { quantity, comment, item: product } = this;
      this.setLoader(true, 'placing');
      this.$api
        .placeOrder({ comment, items: [{ quantity, product: product.id }] })
        .then(() => {
          this.setLoader(false, 'placing');
          this.placed = true;
        })
        .catch(err => {
          this.setLoader(false, 'placing');
          let message;
          if (err.response?.data) {
            const { title, detail } = err.response.data;
            message = `${title}: ${detail}`;
          }
          this.errorMessage = message || err.message;
        });
    },
  },
  created() {
    this.loadItem(this.slug);
  },
};
</script>

<style type="text/css" lang="scss">
.order-item {
  display: flex;
  flex-direction: row;

  .item-info {
    display: flex;
    flex-direction: row;
    align-items: center;

    .tile-image {
      width: 70px;
    }
  }
}
@media only screen and (max-width: 640px) {
  .order-item {
    flex-direction: column;

    .item-info {
      flex-direction: column;

      .tile-image {
        width: 200px !important;
        height: fit-content;
        margin: 0 !important;

        img {
          width: 100%;
          height: auto;
        }
      }
      .item-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 15px;
        > * {
          padding: 5px 0;
        }
      }
    }

    .actions {
      margin: 0 !important;
    }
  }
}
</style>
