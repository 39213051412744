<template>
  <div :class="{ 'has-error': !!error }">
    <slot />
    <span v-if="error" class="error-hint">{{ error }}</span>
  </div>
</template>

<script>
export default {
  name: 'FormField',
  props: ['error'],
};
</script>
